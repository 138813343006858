import {
	ref, onValue,
	push,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	TextFieldProps,
	Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { QrScanner } from "@yudiel/react-qr-scanner";
import { auth, base, db } from "../../firebase.js";
import {
	Cards, Subscribtions, Orders, Visits,
} from "../../interfaces/firebaseData";

interface Visit {
	cardId?: string;
	sellerEmail?: string;
	dateTime?: string;
	key?: number;
	keyReturned?: boolean;
}

const styles = () => createStyles({});

interface Props extends WithStyles<typeof styles> {
	open: boolean;
	onClose: () => void;
}

interface CheckResult{
	success: boolean;
	message?:string;
}
function addDays(date: Date, number: number) {
	return new Date(date.setDate(date.getDate() + number));
}
export const visitCheck = (subs: Subscribtions, cards: Cards, orders: Orders, visits: Visits, cardId?: string):CheckResult => {
	let message;
	const card = cardId ? cards?.[cardId] : undefined;
	const sub = card ? subs?.[card?.subscribtionPacketId] : undefined;
	const order = Object.entries(orders)?.map((e) => { return { id: e[0], ...e[1] }; })?.reverse()?.filter((o) => o?.cardId === cardId)?.[0];
	if (order && sub && card) {
		//check days valid
		const expDate = addDays(new Date(order.dateOfActivation), sub?.daysValid || 30);
		if (expDate < new Date()) {
			return {
				message: `Изтекъл абонамент на ${expDate.toLocaleDateString("bg-BG")}`,
				success: false,
			};
		}

		//check time frame
		if (sub?.hourStart && sub?.hourEnd) {
			const currentHour = new Date().getHours();
			const hourStart = new Date(sub.hourStart).getHours();
			const hourEnd = new Date(sub.hourEnd).getHours();
			if (currentHour < hourStart || currentHour > hourEnd) {
				return {
					message: "Невалиден час за посещение",
					success: false,
				};
			}
		}
		//check max visits
		if (sub.maxVisits) {
			const relevantVisits = Object.entries(visits)?.map((e) => { return { id: e[0], ...e[1] }; })?.filter((v) => v?.cardId === cardId)?.length || 0;
			if (relevantVisits >= sub.maxVisits) {
				return {
					message: "Достигнат лимит на брой посещения",
					success: false,
				};
			} else {
				message = `Оставащ брой посещения: ${sub.maxVisits - relevantVisits}`;
			}
		}
	} else {
		return {
			message: "Няма намерен абонамент",
			success: false,
		};
	}
	return {
		success: true,
		message,
	};
};


// eslint-disable-next-line @typescript-eslint/naming-convention
function NewCard(props: Props) {
	const [scanMode, setScanMode] = useState<boolean>(false);
	const [cards, setCards] = useState<Cards>({});
	const [subs, setSubs] = useState<Subscribtions>({});
	const [visits, setVisits] = useState<Visits>({});
	const [orders, setOrders] = useState<Orders>({});
	const [visit, setVisit] = useState<Visit | undefined>();
	const [clicked, setClicked] = useState<boolean>(false);
	useEffect(() => {
		onValue(ref(db, `${base}/Cards`), (snapshot) => {
			if (snapshot.exists()) {
				setCards(snapshot.val());
			}
		});
		onValue(ref(db, `${base}/SubscribtionPackets`), (snapshot) => {
			if (snapshot.exists()) {
				setSubs(snapshot.val());
			}
		});
		onValue(ref(db, `${base}/Orders`), (snapshot) => {
			if (snapshot.exists()) {
				setOrders(snapshot.val());
			}
		});
		onValue(ref(db, `${base}/Visits`), (snapshot) => {
			if (snapshot.exists()) {
				setVisits(snapshot.val());
			}
		});
	}, []);
	const check = visitCheck(subs, cards, orders, visits, visit?.cardId);
	const close = () => {
		setClicked(false);
		setVisit(undefined);
		props.onClose();
	};
	const set = (id?: string) => {
		if (id) {
			setVisit({
				...visit,
				cardId: id,
				sellerEmail: auth.currentUser?.email || "",
				dateTime: new Date().toString(),
			});
		}
	};
	const add = async () => {
		setClicked(true);
		await push(ref(db, `${base}/Visits`), {
			...visit,
			dateTimeSold: new Date().toString(),
		});
		close();
	};
	const cardOptions = Object.entries(cards).map(([id, value]) => {
		return { id, label: value.buyerName };
	});
	return (
		<Dialog open={props.open}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Ново посещение
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box width="400px" maxWidth="100%">
					<Typography mb={1}>Име на собственик*</Typography>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={cardOptions}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Име" />}
						onChange={(e, value) => set(value?.id)}
						value={cardOptions?.find((c) => c.id === visit?.cardId) || { id: "", label: "Избери" }}
					/>
					{scanMode ? (
						<QrScanner
							constraints={{
								facingMode: "environment",
							}}
							onResult={(result) => {
								set(result?.getText().toString());
								setScanMode(false);
							}}
							onError={(error) => console.log(error?.message)}
						/>
					) : (
						<Button
							fullWidth
							sx={{ mb: 2 }}
							variant="outlined"
							onClick={() => setScanMode(true)}
						>
							Сканирай QR
						</Button>
					)}
					<Typography mb={1}>Ключ*</Typography>
					<TextField
						sx={{ mb: 2 }}
						fullWidth
						label="Ключ"
						type="number"
						// eslint-disable-next-line radix
						onChange={(e) => setVisit({ ...visit, key: parseInt(e.target.value) })}
						required
					/>
					{check.message && <Typography mb={3} color={check.success ? "primary" : "error"}>{check.message}</Typography>}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!check.success || clicked || !visit?.key}
					onClick={add}
				>
					Добави посещение
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(NewCard);
