import { ref, onValue, update } from "firebase/database";
import React, {
	useCallback, useEffect, useRef, useState,
} from "react";
import {
	Box,
	Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Tab, Tabs, TextField, Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import {
	CameraAlt, FileUpload, Close as CloseIcon,
} from "@mui/icons-material";
import Webcam from "react-webcam";
// eslint-disable-next-line import/no-extraneous-dependencies
import { base, db } from "../../firebase.js";
import { Cards, Subscribtions } from "../../interfaces/firebaseData";

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

interface Card {
	subscribtionPacketId?: string;
	buyerName?: string;
	buyerPicture?: string | null;
	phone?: number | null;
}

const styles = () => createStyles({
	tabRoot: {
		flex: 1,
		maxWidth: "none",
	},
	imgWrapper: {
		width: "400px",
		maxWidth: "100%",
		margin: "20px auto 0 auto",
	},
	imgHolder: {
		overflow: "hidden",
		background: "gray",
		marginBottom: "20px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	qr: {
		display: "block",
		width: "300px",
		maxWidth: "100%",
	},
});

interface Props extends WithStyles<typeof styles> {
	onClose: () => void;
	// eslint-disable-next-line react/require-default-props
	editCardId?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function EditCard(props: Props) {
	const { classes } = props;
	const webcamRef = useRef<any>(null);
	const cardRef = useRef<any>(null);
	const [subs, setSubs] = useState<Subscribtions>({});
	const [cards, setCards] = useState<Cards>({});
	const [card, setCard] = useState<Card | undefined>();
	const [tabValue, setTabValue] = React.useState(0);
	const [clicked, setClicked] = useState<boolean>(false);

	useEffect(() => {
		onValue(ref(db, `${base}/SubscribtionPackets`), (snapshot) => {
			if (snapshot.exists()) {
				setSubs(snapshot.val());
			}
		});
		onValue(ref(db, `${base}/Cards`), (snapshot) => {
			if (snapshot.exists()) {
				setCards(snapshot.val());
			}
		});
		if (props.editCardId) {
			setCard(cards[props.editCardId]);
		}
	}, [props.editCardId]);
	const handleChange = (event: SelectChangeEvent) => {
		setCard({ ...card, subscribtionPacketId: event.target.value });
	};
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
		setCard({ ...card, buyerPicture: undefined });
	};
	const imgUpload = () => {
		const file = (window?.document?.querySelector("input[type=file]") as any)?.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				setCard({ ...card, buyerPicture: reader.result?.toString() });
			};
		}
	};
	cardRef.current = card;
	const capture = useCallback(() => {
		if (webcamRef?.current) {
			const imageSrc = (webcamRef.current as any).getScreenshot();
			setCard({ ...cardRef.current, buyerPicture: imageSrc });
		}
	}, [webcamRef]);

	const close = () => {
		setCard(undefined);
		setClicked(false);
		props.onClose();
	};
	const updateCard = async () => {
		setClicked(true);
		if (!card) {
			console.error("Card data is undefined.");
			return;
		}
		const uid = ref(db, `${base}/Cards/${props.editCardId}`);
		await update(uid, card);
		close();
	};

	return (
		<Dialog open={!!props.editCardId}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Редактирай карта
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<>
					<Typography mb={1}>Вид карта*</Typography>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						label="Карта"
						value={card?.subscribtionPacketId || ""}
						onChange={handleChange}
						fullWidth
						sx={{ mb: 3 }}
						required
					>
						{Object.entries(subs).map(([key, value]) => <MenuItem value={key}>{value.name}</MenuItem>)}
					</Select>
					<Typography mb={1}>Име на собственик*</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Име"
						value={card?.buyerName || ""}
						onChange={(e) => setCard({ ...card, buyerName: e.target.value })}
						required
					/>
					<Typography mb={1}>Телефон</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Телефон"
						value={card?.phone || ""}
						onChange={(e) => {
							const val = e?.target?.value;
							// eslint-disable-next-line radix
							setCard({ ...card, phone: val ? parseInt(val) : null });
						}}
						type="number"
					/>
					<Typography mb={1}>Снимка</Typography>
					<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
						<Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
							<Tab
								className={classes.tabRoot}
								label={(
									<Grid container alignItems="center" justifyContent="center">
										<Box mr={1}><FileUpload /></Box>
										от файл
									</Grid>
								)}
								{...a11yProps(0)}
							/>
							<Tab
								className={classes.tabRoot}
								label={(
									<Grid container alignItems="center" justifyContent="center">
										<Box mr={1}><CameraAlt /></Box>
										Камера
									</Grid>
								)}
								{...a11yProps(1)}
							/>
						</Tabs>
					</Box>
					<Box className={classes.imgWrapper}>
						<Button
							variant="outlined"
							component={0 === tabValue ? "label" : "div"}
							fullWidth
							sx={{ mb: 2 }}
							// eslint-disable-next-line no-nested-ternary
							onClick={() => {
								if (1 === tabValue) {
									if (card?.buyerPicture) {
										setCard({ ...card, buyerPicture: undefined });
									} else {
										capture();
									}
								}
							}}
						>
							{0 === tabValue && (
								<>
									качи Снимка
									<Box sx={{ display: "none" }}>
										<input
											id="files"
											style={{ visibility: "hidden" }}
											type="file"
											accept="image/*"
											onChange={imgUpload}
										/>
									</Box>
								</>
							)}
							{1 === tabValue && !card?.buyerPicture && "Снимай"}
							{1 === tabValue && card?.buyerPicture && "Нова снимка"}
						</Button>
						<Box className={classes.imgHolder}>
							{card?.buyerPicture && <img width="100%" height="auto" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={card?.buyerPicture as string} alt="" />}
							{!card?.buyerPicture && 1 === tabValue && (
								<Webcam
									audio={false}
									height="auto"
									width="100%"
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									videoConstraints={{ facingMode: "environment" }}
								/>
							)}
						</Box>
					</Box>
				</>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!card?.buyerName || !card.subscribtionPacketId || clicked}
					onClick={updateCard}
				>
					Редактирай
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(EditCard);
