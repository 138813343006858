import React from "react";
import {
	Grid, IconButton,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { FileDownload, Print } from "@mui/icons-material";

const styles = () => createStyles({
	qr: {
		display: "block",
		width: "300px",
		maxWidth: "100%",
	},
});

interface Props extends WithStyles<typeof styles> {
	qr: string;
	// eslint-disable-next-line react/require-default-props
	name?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function QR(props: Props) {
	const { classes, qr, name } = props;
	const qrDownload = () => {
		const link = document.createElement("a");
		link.href = qr;
		link.download = name || "qr";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	const qrPrint = async () => {
		const printDiv = window.document.getElementById("qr") as any;
		const printWindow = window.open("", "_blank", "left=0, top=0, width=800, height=500, toolbar=0, scrollbars=0, status=0") as any;
		printWindow.document.write(printDiv.innerHTML);
		printWindow.document.close();
		printWindow.focus();
		await printWindow.print();
		printWindow.close();
	};
	return (
		<>
			<div id="qr">
				<img
					src={qr}
					className={classes.qr}
				/>
			</div>
			<Grid container gap={1} justifyContent="center">
				<IconButton onClick={qrDownload} color="primary">
					<FileDownload />
				</IconButton>
				<IconButton onClick={qrPrint} color="primary">
					<Print />
				</IconButton>
			</Grid>
		</>
	);
}
export default withStyles(styles)(QR);
