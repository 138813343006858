import { createTheme } from "@mui/material/styles";
import { bgBG as dataGridBG } from "@mui/x-data-grid";
import { bgBG as bg } from "@mui/material/locale";
// eslint-disable-next-line import/no-extraneous-dependencies
import { ruRU } from "@mui/x-date-pickers/locales";

const theme = createTheme({
	palette: {
		primary: {
			main: "#27ae60",
			contrastText: "#fff",
		},
		secondary: {
			main: "#50c346",
		},
	},
	components: {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		MuiButton: {
			styleOverrides: {
				root: {
					boxShadow: "none",
				},
			},
		},
	},
}, dataGridBG, bg, ruRU);

export default theme;
