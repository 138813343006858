import React from "react";
import {
	Box, List, Typography, Divider, IconButton, ListItem, AppBar, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Container, Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ViewListIcon from "@mui/icons-material/ViewList";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

const menuItems = [
	{
		id: "1",
		text: "Посещения",
		icon: <DirectionsWalkIcon />,
		path: "/visits",
	},
	{
		id: "2",
		text: "Поръчки",
		icon: <ViewListIcon />,
		path: "/orders",
	},
	{
		id: "3",
		text: "Карти",
		icon: <CreditCardIcon />,
		path: "/cards",
	},
	{
		id: "4",
		text: "Абонаменти",
		icon: <LoyaltyIcon />,
		path: "/subscriptions",
	},
];
interface Props {
	children: React.ReactNode
}
// eslint-disable-next-line @typescript-eslint/naming-convention
function AppFrame({ children }: Props) {
	const [open, setOpen] = React.useState(false);
	const navigate = useNavigate();

	return (
		<>
			<AppBar
				position="fixed"
				sx={{
					background: "linear-gradient(to right, #005aa7, #5baff5)",
				}}
			>
				<Container>
					<Grid container direction="row" alignItems="center" sx={{ padding: "10px 0px" }} flexWrap="nowrap">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={() => setOpen(true)}
							edge="start"
							sx={{ mr: 2 }}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
							Club17 |
							{" "}
							{getAuth().currentUser?.email}
						</Typography>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={() => signOut(getAuth())}
							edge="end"
						>
							<LogoutIcon />
						</IconButton>
					</Grid>
				</Container>
			</AppBar>
			<SwipeableDrawer
				anchor="left"
				open={open}
				onOpen={() => setOpen(true)}
				onClose={() => setOpen(false)}
			>
				<Box minWidth={240}>
					<Box sx={{ display: "flex", justifyContent: "flex-end", padding: "9.5px" }}>
						<IconButton onClick={() => setOpen(false)}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
					<Divider />
					<List>
						{menuItems.map((item) => (
							<ListItem key={item.text} disablePadding>
								<ListItemButton onClick={() => {
									navigate(item.path);
									setOpen(false);
								}}
								>
									<ListItemIcon>{item.icon}</ListItemIcon>
									<ListItemText primary={item.text} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Box>
			</SwipeableDrawer>
			<Box mt="75px">
				<Container>
					{children}
				</Container>
			</Box>
		</>
	);
}
export default AppFrame;
