import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// eslint-disable-next-line import/no-extraneous-dependencies
import bg from "date-fns/locale/bg";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from "@mui/x-date-pickers";
import Login from "./components/Login";
import Orders from "./components/Orders";
import Visits from "./components/Visits";
import theme from "./theme";
import Cards from "./components/Cards";
import Subscriptions from "./components/Subscriptions";

// eslint-disable-next-line @typescript-eslint/naming-convention
function PrivateRoutes() {
	return (
		<Routes>
			<Route path="/visits" element={<Visits />} />
			<Route path="/orders" element={<Orders />} />
			<Route path="/cards" element={<Cards />} />
			<Route path="/subscriptions" element={<Subscriptions />} />
			<Route path="/" element={<Orders />} />
		</Routes>
	);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function PublicRoutes() {
	return (
		<Routes>
			<Route path="*" element={<Login />} />
		</Routes>
	);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function App() {
	const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
	useEffect(() => undefined, [onAuthStateChanged(getAuth(), (user) => {
		if (user) {
			setIsUserLoggedIn(!!user);
		} else {
			setIsUserLoggedIn(false);
		}
	})]);

	const routes = () => {
		if (isUserLoggedIn) {
			return <PrivateRoutes />;
		}
		return <PublicRoutes />;
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={bg}>
				{
					routes()
				}
			</LocalizationProvider>
		</ThemeProvider>
	);
}


const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
