// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
	apiKey: "AIzaSyA2xEy2Ur86O6JXSoKTVMR7pwS_ta1sDRU",
	authDomain: "fitness-809de.firebaseapp.com",
	projectId: "fitness-809de",
	storageBucket: "fitness-809de.appspot.com",
	messagingSenderId: "444592622577",
	appId: "1:444592622577:web:043d3545662723d4bdd250",
	databaseURL: "https://fitness-809de-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getDatabase(app);
export const base = "Club17";
// export const base = "fitness";
export const editors = ["ren4uuu@hotmail.com", "devs4oryou@gmail.com"];
export default app;
