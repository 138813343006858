import React from "react";
import { ref, remove } from "firebase/database";
import {
	Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";
import { base, db } from "../../firebase.js";

const styles = () => createStyles({

});

interface Props extends WithStyles<typeof styles> {
	// eslint-disable-next-line react/require-default-props
	cardId?: string;
	onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function NewCard(props: Props) {
	const close = () => {
		props.onClose();
	};
	const deleteOrder = async () => {
		await remove(ref(db, `${base}/Cards/${props.cardId}`));
		close();
	};
	return (
		<Dialog open={!!props.cardId}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Изтриване на поръчка
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box width="400px" maxWidth="100%">
					<Typography mb={1}>Сигурни ли сте, че искате да изтриете тази карта?</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="error" onClick={deleteOrder}>
					Да
				</Button>
				<Button variant="contained" color="inherit" onClick={close}>
					Не
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(NewCard);
