import { ref, push } from "firebase/database";
import React, {
	useState,
} from "react";
import {
	Box,
	Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography, Checkbox, FormControlLabel,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { TimePicker } from "@mui/x-date-pickers";
import { base, db } from "../../firebase.js";

interface Card {
	name?: string;
	price?: number;
	daysValid?: number;
	maxVisits?: number;
	hourStart?: string;
	hourEnd?: string;
	note?: string;
}

const styles = () => createStyles({

});

interface Props extends WithStyles<typeof styles> {
	open: boolean;
	onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function NewSub(props: Props) {
	const [sub, setSub] = useState<Card>();
	const [maxVisits, setMaxVisits] = useState<boolean>(false);
	const [frame, setFrame] = useState<boolean>(false);

	const add = async () => {
		console.log(sub);
		await push(ref(db, `${base}/SubscribtionPackets`), sub);
		setSub(undefined);
		props.onClose();
	};
	const visitsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		setMaxVisits(checked);
		if (!checked) {
			setSub({ ...sub, maxVisits: 0 });
		}
	};
	const frameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		setFrame(checked);
		if (!checked) {
			setSub({ ...sub, hourStart: "", hourEnd: "" });
		}
	};
	return (
		<Dialog open={props.open}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Добави абонамент
				<IconButton
					aria-label="close"
					onClick={props.onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box width="400px" maxWidth="100%">
					<Typography mb={1}>Име*</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Име"
						onChange={(e) => setSub({ ...sub, name: e.target.value })}
						required
					/>
					<Typography mb={1}>Цена(лв)*</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Цена"
						type="number"
						// eslint-disable-next-line radix
						onChange={(e) => setSub({ ...sub, price: parseInt(e.target.value) })}
						required
					/>
					<Typography mb={1}>Период на валидност(дни)*</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Период на валидност(дни)"
						type="number"
						// eslint-disable-next-line radix
						onChange={(e) => setSub({ ...sub, daysValid: parseInt(e.target.value) })}
						required
					/>
					<FormControlLabel
						sx={{ mb: 1 }}
						label="Макс. брой посещения"
						control={(
							<Checkbox
								color="primary"
								checked={maxVisits}
								onChange={visitsChange}
							/>
						)}
					/>
					{maxVisits && (
						<TextField
							sx={{ mb: 2 }}
							fullWidth
							label="Макс. брой посещения"
							type="number"
							// eslint-disable-next-line radix
							onChange={(e) => setSub({ ...sub, maxVisits: parseInt(e.target.value) })}
							inputProps={{ min: 1 }}
							defaultValue={1}
							value={sub?.maxVisits || 0}
						/>
					)}
					<FormControlLabel
						sx={{ mb: 1 }}
						label="Времева рамка (от - до)"
						control={(
							<Checkbox
								color="primary"
								checked={frame}
								onChange={frameChange}
							/>
						)}
					/>
					{frame && (
						<Grid container alignItems="center" justifyContent="space-between" width="100%" mb={2}>
							<Box width="calc(50% - 10px)">
								<TimePicker value={sub?.hourStart ? new Date(sub.hourStart) : undefined} label="от" ampm={false} onChange={(e: Date | null) => setSub({ ...sub, hourStart: e?.toString() })} />
							</Box>
							<Box width="calc(50% - 10px)">
								<TimePicker value={sub?.hourEnd ? new Date(sub.hourEnd) : undefined} label="до" ampm={false} onChange={(e: Date | null) => setSub({ ...sub, hourEnd: e?.toString() })} />
							</Box>
						</Grid>
					)}
					<Typography mb={1}>Бележка</Typography>
					<TextField
						sx={{ mb: 3 }}
						fullWidth
						label="Бележка"
						onChange={(e) => setSub({ ...sub, note: e.target.value })}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={add} disabled={!sub?.name || !sub.price || !sub.daysValid}>
					Добави
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(NewSub);
