import {
	ref, onValue,
	push,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import {
	Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, TextFieldProps, Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { WithStyles, createStyles, withStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-extraneous-dependencies
import { QrScanner } from "@yudiel/react-qr-scanner";
import { auth, base, db } from "../../firebase.js";
import { Cards, Subscribtions } from "../../interfaces/firebaseData";

interface Order {
	cardId?: string;
	sellerEmail?: string;
	dateTimeSold?: string;
	dateOfActivation?: string;
	price?: number;
}

const styles = () => createStyles({

});

interface Props extends WithStyles<typeof styles> {
	open: boolean;
	onClose: () => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function NewCard(props: Props) {
	const [scanMode, setScanMode] = useState<boolean>(false);
	const [cards, setCards] = useState<Cards>({});
	const [subs, setSubs] = useState<Subscribtions>({});
	const [order, setOrder] = useState<Order | undefined>({
		dateOfActivation: new Date().toString(),
	});
	const [clicked, setClicked] = useState<boolean>(false);
	useEffect(() => {
		onValue(ref(db, `${base}/Cards`), (snapshot) => {
			if (snapshot.exists()) {
				setCards(snapshot.val());
			}
		});
		onValue(ref(db, `${base}/SubscribtionPackets`), (snapshot) => {
			if (snapshot.exists()) {
				setSubs(snapshot.val());
			}
		});
	}, []);
	const close = () => {
		setClicked(false);
		setOrder({
			dateOfActivation: new Date().toString(),
		});
		props.onClose();
	};
	const set = (id?: string) => {
		if (id) {
			setOrder({
				...order,
				cardId: id,
				price: subs[cards[id].subscribtionPacketId].price,
				sellerEmail: auth.currentUser?.email || "",
			});
		}
	};
	const add = async () => {
		setClicked(true);
		await push(ref(db, `${base}/Orders`), {
			...order,
			dateTimeSold: new Date().toString(),
		});
		close();
	};
	const cardOptions = Object.entries(cards).map(([id, value]) => { return { id, label: value.buyerName }; });
	return (
		<Dialog open={props.open}>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				Нова поръчка
				<IconButton
					aria-label="close"
					onClick={close}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<Box width="400px" maxWidth="100%">
					<Typography mb={1}>Име на собственик*</Typography>
					<Autocomplete
						disablePortal
						id="combo-box-demo"
						options={cardOptions}
						sx={{ width: "100%", mb: 2 }}
						renderInput={(params: TextFieldProps) => <TextField {...params} label="Име" />}
						onChange={(e, value) => set(value?.id)}
						value={cardOptions?.find((c) => c.id === order?.cardId) || { id: "none", label: "Избери" }}
					/>
					{scanMode ? (
						<QrScanner
							constraints={{
								facingMode: "environment",
							}}
							onResult={(result) => {
								set(result?.getText().toString());
								setScanMode(false);
							}}
							onError={(error) => console.log(error?.message)}
						/>
					) : (
						<Button
							fullWidth
							sx={{ mb: 2 }}
							variant="outlined"
							onClick={() => setScanMode(true)}
						>
							Сканирай QR
						</Button>
					)}
					<Typography mb={1}>Дата на активация*</Typography>
					<Box width="100%">
						<DatePicker
							value={order?.dateOfActivation ? new Date(order.dateOfActivation) : undefined}
							label="Дата на активация"
							onChange={(e: Date | null) => setOrder({ ...order, dateOfActivation: e?.toString() })}
						/>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={!order?.dateOfActivation || !order.price || clicked}
					onClick={add}
				>
					Поръчай
				</Button>
			</DialogActions>
		</Dialog>
	);
}
export default withStyles(styles)(NewCard);
