import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
	Container, Box, Typography, TextField, Button, Grid,
} from "@mui/material";
import { auth } from "../firebase.js";

// eslint-disable-next-line @typescript-eslint/naming-convention
function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const onLogin = (e: { preventDefault: () => void; }) => {
		e.preventDefault();
		signInWithEmailAndPassword(auth, email, password)
			.then((userCredential) => {
				// Signed in
				const { user } = userCredential;
				console.log(user);
				console.log("user logged in");
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode, errorMessage);
			});
	};

	return (
		<Container component="main" maxWidth="xs">
			<Box
				sx={{
					marginTop: 18,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography component="h1" variant="h5">
					Вход
				</Typography>
				<Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1, mb: 3 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Имейл адрес"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Парола"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
						Вход
					</Button>
					<Grid container>
						<Grid item xs>
							{/* <Link href="/reset-password" variant="body2">
								Забравена парола?
							</Link> */}
						</Grid>
						<Grid item>
							{/* <Link href="/register" variant="body2">
								{"Нямате акаунт? Регистрация"}
							</Link> */}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Container>
	);
}

export default Login;
