/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/naming-convention
const SearchWrapper = styled("div")(({ theme }) => ({
	position: "relative",
	display: "flex",
	alignItems: "center",
	width: "400px",
	maxWidth: "100%",
	backgroundColor: "#F0F0F0",
	borderRadius: theme.shape.borderRadius,
	marginBottom: "20px",
	"&:hover": {
		backgroundColor: "#e0e0e0",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 1),
	display: "flex",
	alignItems: "center",
	color: "#757575",
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	width: "100%",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "transparent",
		border: "none",
		width: "100%",
		"&:focus": {
			width: "100%",
		},
	},
}));

interface SearchBarProps {
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function SearchBar({ value, onChange }: SearchBarProps) {
	return (
		<SearchWrapper>
			<SearchIconWrapper>
				<SearchIcon />
			</SearchIconWrapper>
			<SearchInput placeholder="Име..." value={value} onChange={onChange} />
		</SearchWrapper>
	);
}

export default SearchBar;
