import React, { useEffect, useState } from "react";
import { onValue, ref, set } from "firebase/database";
// import Toolbar from "@mui/material/Toolbar";
import {
	DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
	Box, Button, Checkbox, Grid, Typography,
} from "@mui/material";
import { base, db } from "../firebase.js";
import AppFrame from "./shared/AppFrame";
import SearchBar from "./shared/SearchBar";
import NewOrder from "./shared/NewVisit";
import { Cards, Visits, Visit as VisitF } from "../interfaces/firebaseData";

interface Visit extends VisitF {
	id: string;
}


// eslint-disable-next-line @typescript-eslint/naming-convention
function Orders() {
	const [rows, setRows] = useState<Visit[]>([]);
	const [cards, setCards] = useState<Cards>();
	const [open, setOpen] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState("");
	const columns: GridColDef[] = [
		{
			field: "cardId",
			headerName: "Име",
			minWidth: 220,
			valueFormatter: (v: GridValueFormatterParams) => cards?.[v?.value || ""]?.buyerName,
		},
		{
			field: "dateTime",
			headerName: "Дата и час на посещение",
			minWidth: 200,
			valueFormatter: (v: GridValueFormatterParams) => new Date(v.value).toLocaleString("bg-Bg"),
		},
		{
			field: "sellerEmail",
			headerName: "Служител",
			minWidth: 220,
		},
		{
			field: "key",
			headerName: "Ключ",
			minWidth: 60,
			valueFormatter: (v: GridValueFormatterParams) => v?.value || "",
		},
		{
			field: "keyReturned",
			headerName: "Върнат ключ",
			minWidth: 60,
			renderCell: (params: GridRenderCellParams) => {
				return (
					<Checkbox
						onClick={async (e: any) => {
							const uid = ref(db, `${base}/Visits/${params.row.id}/keyReturned`);
							await set(uid, e.target.checked);
						}}
						checked={params.row?.keyReturned}
					/>
				);
			},
		},

	];

	useEffect(() => {
		const queryVisits = ref(db, `${base}/Visits`);
		const queryCards = ref(db, `${base}/Cards`);
		onValue(queryCards, (snapshot) => {
			const data: Cards = snapshot.val();
			if (snapshot.exists()) {
				setCards(data);
			} else {
				setCards(undefined);
			}
		});
		onValue(queryVisits, (snapshot) => {
			const data: Visits = snapshot.val();
			if (snapshot.exists()) {
				const newRows: Visit[] = [];
				Object.entries(data).forEach((entry) => {
					const [key, value] = entry;
					newRows.push({
						id: key,
						...value,
					});
				});
				setRows(newRows.reverse());
			} else {
				setRows([]);
			}
		});
	}, []);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	return (
		<AppFrame>
			<NewOrder open={open} onClose={() => { setOpen(false); }} />
			<Grid container mb={3} alignItems="flex-start">
				<Box flex={1}>
					<Typography variant="h6">Посещения</Typography>
				</Box>
				<Button variant="outlined" onClick={() => setOpen(true)}>Създай ново</Button>
			</Grid>
			<SearchBar value={searchValue} onChange={handleSearchChange} />
			<div style={{ width: "100%", marginBottom: "40px", height: "calc(100svh - 240px)" }}>
				<DataGrid
					disableColumnSelector
					rowSelection={false}
					rowHeight={80}
					rows={rows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20]}
				/>
			</div>
		</AppFrame>
	);
}

export default Orders;
