import React, { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
// import Toolbar from "@mui/material/Toolbar";
import {
	DataGrid, GridColDef, GridValueFormatterParams, GridValueGetterParams,
} from "@mui/x-data-grid";
import {
	Box, Button, Grid, Typography,
} from "@mui/material";
import { base, db } from "../firebase.js";
import AppFrame from "./shared/AppFrame";
import SearchBar from "./shared/SearchBar";
import { Subscribtion as SubFirebase } from "../interfaces/firebaseData";
import NewSub from "./shared/NewSub";

interface Subscribtion extends SubFirebase {
	id: string;
}
interface SubsData {
	[id: string]: SubFirebase
}


// eslint-disable-next-line @typescript-eslint/naming-convention
function Subscriptions() {
	const [rows, setRows] = useState<Subscribtion[]>([]);
	const [open, setOpen] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState("");
	const columns: GridColDef[] = [
		{
			field: "name",
			headerName: "Име",
			minWidth: 200,
		},
		{
			field: "price",
			headerName: "Цена(лв)",
			minWidth: 50,
			valueFormatter: (params: GridValueFormatterParams) => `${params.value}лв`,
		},
		{ field: "daysValid", headerName: "Валидност(дни)", minWidth: 130 },
		{
			field: "maxVisits",
			headerName: "Макс. брой посещения",
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams) => (params.value ? params.value : "неограничен"),
		},
		{
			field: "hourStart",
			headerName: "Времева рамка",
			minWidth: 130,
			valueGetter: (params: GridValueGetterParams) => (params.row.hourStart ? `${new Date(params.row.hourStart).getHours()} - ${new Date(params.row.hourEnd).getHours()}ч.` : "няма"),
		},
		{
			field: "note",
			headerName: "Бележка",
			minWidth: 130,
			valueFormatter: (params: GridValueFormatterParams) => (params.value ? params.value : "няма"),
		},
	];

	useEffect(() => {
		const querySubs = ref(db, `${base}/SubscribtionPackets`);
		return onValue(querySubs, (snapshot) => {
			const data: SubsData = snapshot.val();
			if (snapshot.exists()) {
				const newRows: Subscribtion[] = [];
				Object.entries(data).forEach((entry) => {
					const [key, value] = entry;
					newRows.push({
						id: key,
						...value,
					});
				});
				setRows(newRows.reverse());
			} else {
				setRows([]);
			}
		});
	}, []);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	const filteredRows = rows.filter((row) => {
		return row.name.toLowerCase().includes(searchValue.toLowerCase());
	});

	return (
		<AppFrame>
			<NewSub open={open} onClose={() => { setOpen(false); }} />
			<Grid container mb={3} alignItems="flex-start">
				<Box flex={1}>
					<Typography variant="h6">Абонаменти</Typography>
				</Box>
				<Button variant="outlined" onClick={() => setOpen(true)}>Създай нов</Button>
			</Grid>
			<SearchBar value={searchValue} onChange={handleSearchChange} />
			<div style={{ width: "100%", marginBottom: "40px", height: "calc(100svh - 240px)" }}>
				<DataGrid
					disableColumnSelector
					rowSelection={false}
					rowHeight={80}
					rows={filteredRows}
					columns={columns}
					initialState={{
						pagination: {
							paginationModel: { page: 0, pageSize: 10 },
						},
					}}
					pageSizeOptions={[10, 20]}
				/>
			</div>
		</AppFrame>
	);
}

export default Subscriptions;
